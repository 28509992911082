<template>
  <div>
    <div class="container d-flex justify-content-between">
      <div>
        <h5>Equipo</h5>
      </div>
      <div>
        <b-button :to="{ name: 'Nuevo Miembro' }">Agregar</b-button>
      </div>
    </div>
    <hr />
    <tabla-equipo></tabla-equipo>
  </div>
</template>

<script>
import TablaEquipo from "@/components/equipo/table.vue";
export default {
  components: {
    TablaEquipo,
  },
};
</script>

<style></style>
